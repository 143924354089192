import styled from "styled-components"

const StyledAlbumGrid = styled.div`
  position: relative;
    display: grid;
    grid-template: auto / calc( 50% - 0.375em ) calc( 50% - 0.375em );
    grid-gap: 0.75em;
    margin: 0 auto 3em;
    user-select: none;
    @media ( min-width: 38em ) {
        grid-template: auto / repeat(3, 1fr);
    }
    @media ( min-width: 48em ) {
      grid-gap: 1.25em;
    }
    @media ( min-width: 56em ) {
        grid-template: auto / repeat(4, 1fr);
    }
    
    @media ( min-width: 76em ) {
      grid-template: auto / repeat(5, 1fr);
    }

    @media ( min-width: 48em ) and ( max-width: 68em )  {
      .show-player .has-player & {
        grid-template: auto / repeat(3, 1fr);
      }
    }

    @media ( min-width: 60em ) and ( max-width: 80em ) {
      .show-player .has-player & {
        grid-template: auto / repeat(4, 1fr);
      }
    }
`

export { StyledAlbumGrid }