import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import usePlayer from "hooks/usePlayer"
import IconPlay from "components/svg/iconPlay"
import IconPause from "components/svg/iconPause"


const AlbumItem = styled.div`
	position: relative;
  padding: 0.875em;
  z-index: 1;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
  .can-hover &:hover::before,
  &:active::before {
    opacity: 1;
  }
  > a {
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  h3 {
    font-size: 0.875em;
    font-weight: 700;
		margin: 1em 0 0;
		line-height: 1.2;
	}
	h4 {
		font-size: 0.875em;
		font-weight: 500;
    margin: 0.25em 0 0;
    text-transform: none;
    line-height: 1.2;
	}
	p {
		font-size: var(--font-tiny);
		line-height: var(--font-tiny-lh);
		margin: 0;
	}
  @media ( min-width: 48em ) {
    padding: 1em;
  }
`

const AlbumItemArtwork = styled.div`
  position: relative;
  z-index: 2;
`

const AlbumItemCoverLink = styled.a`
	position: relative;
  display: block;
  overflow: hidden;
  transform: scale(1) translate3d(0,0,0);
  transition: transform 0.3s ease;
  padding-bottom: 100%;
  background-color: var(--color-offwhite);
  box-shadow: 0 0.25em 1.375em -0.75em rgba(0,0,0,0.6), 0 0.125em 0.25em -0.125em rgba(0,0,0,0.2);
  .can-hover ${AlbumItem}:hover &,
  ${AlbumItem}:active & {
    transform: scale(0.95) translate3d(0,0,0);
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`


const AlbumItemCoverEmpty = styled.div`
    position: relative;
    padding-bottom: 100%;
    background-color: #ddd;
`



const AlbumPlay = styled.button`
  appearance: none;
  position: absolute;
  bottom: 1em;
  right: 1em;
	display: block;
	z-index: 5;
	width: 2em;
	height: 2em;
	background-color: var(--color-background);
	display: grid;
	grid-template: 1fr / 1fr;
	place-items: center;
	cursor: default;
  line-height: 0;
  box-shadow: 0 0.125em 0.5em -0.25em rgba(0,0,0,0.8);
	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
    background-color: var(--color-gray-dark);
    border-radius: inherit;
		opacity: 0;
		transition: opacity 0.2s ease;
	}
	svg {
		width: 0.5em;
		margin-left: 0.125em;
	}
	&.is-playing svg {
		margin-left: 0.06em;
	}
	svg polygon {
		fill: currentColor;
		transition: fill 0.2s ease;
	}
	${AlbumItem}.is-current-artist &::before,
	.can-hover &:hover::before,
	&:active::before {
		opacity: 1;
	}
	.can-hover &:hover svg polygon,
	${AlbumItem}.is-current-artist & svg polygon,
	&:active svg polygon,
  .can-hover &:hover svg path,
	${AlbumItem}.is-current-artist & svg path,
	&:active svg path {
		fill: var(--color-white);
	}
  .can-hover & {
    opacity: 0;
    transform: translate3d(0,0,0) scale(0.8);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  ${AlbumItem}.is-current-artist &,
  .can-hover ${AlbumItem}:hover & {
    opacity: 1;
    transform: translate3d(0,0,0) scale(1);
  }
`

const AlbumItemTag = styled.span`
  position: absolute;
  z-index: 3;
  background-color: var(--color-gray-dark);
  color: var(--color-white);
  font-size: 0.625em;
  font-weight: 700;
  text-transorm: uppercase;
  letter-spacing: 0.075em;
  line-height: 1;
  text-transform: uppercase;
  padding: 0.5em 1em;
  width: 10em;
  text-align: center;
  top: 4.75em;
  right: -2.25em;
  transform: rotate(45deg);
  transform-origin: top right;
  @media ( max-width: 48em ) {
    top: 4.5em;
    right: -2.5em;
  }
`

const MiniAlbum = props => {

  const { 
    loadAndPlayPlaylist, 
    currentAlbum, 
    playerState, 
    showPlayer 
  } = usePlayer()
  
  const album = props.albumData
  const albumImage = album.cover && album.cover.localFile ? album.cover.localFile.childImageSharp.fluid : false
  
  return (
    <AlbumItem 
			itemscope
			itemtype="https://schema.org/MusicAlbum"
			className={ currentAlbum !== null && currentAlbum.id && currentAlbum.id === album.id ? `is-current-artist` : ``} 
    >
      {album.wave === '35' &&
        <AlbumItemTag>New</AlbumItemTag>
      }
      <Link to={`/artists/${album.artist.slug}`} title={`${album.title} by ${album.artist.name}`}></Link>
      <AlbumItemArtwork>
        {albumImage ?
          <AlbumItemCoverLink as={Link} to={`/artists/${album.artist.slug}`}>
            <Img 
              fluid={albumImage} 
              backgroundColor="#000000"
              alt={`${album.title} by ${album.artist.name}`} 
            />
          </AlbumItemCoverLink>
          :
          <AlbumItemCoverEmpty />
        }
				{album.tracks && album.tracks.length > 0 &&
					<AlbumPlay 
						className={playerState === 'playing' && currentAlbum !== null && currentAlbum.id && currentAlbum.id === album.id ? `is-playing` : ``} 
						onClick={() => loadAndPlayPlaylist( album, 0 ) }
					>
						{playerState === 'playing' && currentAlbum !== null && currentAlbum.id && currentAlbum.id === album.id ? <IconPause /> : <IconPlay />}
					</AlbumPlay>
        }
      </AlbumItemArtwork>
      <h3>{album.artist.name}</h3>
      <h4><Link to={`/artists/${album.artist.slug}`}>{album.title}</Link></h4>
      {album.artist.location &&
          <p>{album.artist.location}</p>
      }
    </AlbumItem>
  )
}

export default MiniAlbum
