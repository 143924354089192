import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const StyledNextRelease = styled.p`
    position: relative;
    display: none;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: ${props => props.extraMargin ? `4em 0` : `1.5em 0`};
`

const NextRelease = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            nextRelease
          }
        }
      }
    `
  )
  return (
    <StyledNextRelease {...props}>Releases Every Month &mdash; Next release: {data.site.siteMetadata.nextRelease}</StyledNextRelease>
  )
}

export default NextRelease
