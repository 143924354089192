import React from "react"
import Dropdown from "components/ui/dropdown"

const DirectorySort = ({
  order,
  setOrder,
  orderByArtist,
  albums,
  setAlbums
}) => {
  
  const oldAlbums = albums 
  
  const orders = {
    dateDesc: `New to old`,
    dateAsc: `Old to new`,
    alphaAsc: `A to Z`,
    alphaDesc: `Z to A`
  }

  const handleOrderClick = ( newOrder ) => {
    setOrder( newOrder )
    if ( newOrder == `dateDesc` ) {
      setAlbums(oldAlbums.sort((a, b) => parseInt(a.node.wave, 10) > parseInt(b.node.wave, 10) ? -1 : 1 ))
    }
    else if ( newOrder == `dateAsc` ) {
      setAlbums(oldAlbums.sort((a, b) => parseInt(a.node.wave, 10) > parseInt(b.node.wave, 10) ? 1 : -1 ))
    }
    else if ( newOrder == `alphaAsc` ) {
      if ( orderByArtist ) {
        setAlbums(oldAlbums.sort((a, b) => a.node.temp_artist_sort_name.toLowerCase() > b.node.temp_artist_sort_name.toLowerCase() ? 1 : -1 ))
      }
      else {
        setAlbums(oldAlbums.sort((a, b) => a.node.title.toLowerCase() > b.node.title.toLowerCase() ? 1 : -1 ))
      }
    }
    else if ( newOrder == `alphaDesc` ) {
      if ( orderByArtist ) {
        setAlbums(oldAlbums.sort((a, b) => a.node.temp_artist_sort_name.toLowerCase() > b.node.temp_artist_sort_name ? -1 : 1 ))
      }
      else {
        setAlbums(oldAlbums.sort((a, b) => a.node.title.toLowerCase() > b.node.title ? -1 : 1 ))
      }
    }
  }

  return (
    <Dropdown
      label={orders[order]}
      iconToRight
      minWidth="7em"
    >
      <button 
        onClick={() => handleOrderClick(`dateDesc`)}
        className={order === `dateDesc` ? `isActive` : undefined}
      >New to old</button>
      <button 
        onClick={() => handleOrderClick(`dateAsc`)}
        className={order === `dateAsc` ? `isActive` : undefined}
      >Old to new</button>
      <button 
        onClick={() => handleOrderClick(`alphaAsc`)}
        className={order === `alphaAsc` ? `isActive` : undefined}
      >A to Z</button>
      <button 
        onClick={() => handleOrderClick(`alphaDesc`)}
        className={order === `alphaDesc` ? `isActive` : undefined}
      >Z to A</button>
    </Dropdown>
  )
}

export default DirectorySort