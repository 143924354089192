import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const AwardAnnouncement = () => (
  <StyledAwardAnnouncement as={Link} to="/scripps-howard-award-excellence-in-innovation-in-journalism">
    <p>Winner of the 2021 Scripps Howard  Award for Excellence in Innovation in Journalism</p>
  </StyledAwardAnnouncement>
);
export default AwardAnnouncement;

const StyledAwardAnnouncement = styled.a`
  position: relative;
  display: block;
  background-color: #000;
  color: #fff;
  padding: 1.5em 0;
  box-shadow: inset 0 -1px 0 rgba(255,255,255,0.2);
  .can-hover &:hover,
  &:active {
    text-decoration: underline;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
    z-index: -1;
    box-shadow: inherit;
  }
  p {
    margin: 0;
    text-align: center;
  }
`;