import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Map, { StyledMapContentWrap } from "components/map/map"
import Filters from "components/filters/filters"


const StyledBackdrop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  div {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media ( min-width: 60em ) {
    .show-player & {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
    }
  }
`

const StyledDirectoryMap = styled.div`
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: var(--backdrop-filter);
  }
  @media ( max-width: 60em ) {
    margin-left: -8vw;
    width: 100vw;
    ${StyledMapContentWrap} {
      padding: 2.5em 0.5em;
    }
  }
  @media ( min-width: 60em ) {
    padding: 2em 0 2.5em 2.5em;
    height: 100%;
    .show-player &::after {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
    }
  }
`


const StyledDirectoryContent = styled.div`
  position: relative;
  z-index: 2;
  padding: 1.5em 0 1.5em;
  margin: 0 auto;
  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: var(--color-white);
  }
  &::after {
    background-color: var(--color-background);
    opacity: 0.6;
  }
  @media ( min-width: 48em ) {
    padding: 2.5em 0 2em;
  }
  @media ( min-width: 60em ) {
    .show-player &::before,
    .show-player &::after {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
    }
  }
`


const LayoutDirectory = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "cover.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
      }
    `
  )

  const [showMap, setShowMap] = useState(false)

  return (
    <>
      <Filters 
        filterTab={props.filterTab ? props.filterTab : undefined}
        setShowMap={setShowMap} 
        showMap={showMap} 
      />
      {showMap ?
        <StyledDirectoryMap>
          <StyledBackdrop>
            <Img fluid={data.hero.childImageSharp.fluid} alt="" />
          </StyledBackdrop>
          <Map hideMap={(e) => setShowMap( false )} />
        </StyledDirectoryMap>
        :
        <StyledDirectoryContent>
          <main>
            {props.children}
          </main>
        </StyledDirectoryContent>
      }
    </>
  )
}

export default LayoutDirectory
