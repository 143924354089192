import React from "react"
import styled from "styled-components"
import DirectorySort from "components/directory/sort"

const StyledDirectoryHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3em;
  .pageTitle {
    margin-bottom: 0;
  }
`

const DirectoryHeader = ({
  title,
  order,
  setOrder,
  orderByArtist,
  albums,
  setAlbums
}) => {

  return (
    <StyledDirectoryHeader>
      <h1 className="pageTitle">{title}</h1>
      <DirectorySort
        order={order}
        setOrder={setOrder}
        orderByArtist={orderByArtist || undefined}
        albums={albums}
        setAlbums={setAlbums}
      />
    </StyledDirectoryHeader>
  )
}

export default DirectoryHeader